import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/animate.css";
import "./css/width-full.css";
import "./css/style.less";
import "./css/color-wcc.less";
import React from "react"
import {FaFacebookF, FaInstagram} from 'react-icons/fa'
import {Col, Container, Nav, NavItem, Row} from "reactstrap";
import WccLogo from "./img/wcc-logo-small.png";
import {Link} from "gatsby";
import Helmet from "react-helmet";

export class Layout extends React.Component {

    menuItems = [
        {
            pageName: "home",
            link: "/",
            title: "Home",
        },
        {
            pageName: "registration",
            link: "/registration/",
            title: "Registration",
        },
        {
            pageName: "choirs",
            link: "/choirs/",
            title: "Choirs",
        },
        {
            pageName: "biographies",
            link: "/biographies/",
            title: "Biographies",
        },
        // {
        //     pageName: "calendarOfEvents",
        //     link: "/calendar-of-events/",
        //     title: "Calendar of Events",
        // },
    ];

    render() {
        const {pageName, children} = this.props;

        const menus = this.menuItems.map((item, idx) => (
            <NavItem key={idx} active={pageName === item.pageName}>
                <Link to={item.link} className="nav-link">
                    {item.title}
                </Link>
            </NavItem>
        ));

        return (
            <React.Fragment>
                <Helmet defaultTitle="Whitehorse Community Choir">
                    <html lang="en"/>
                    <meta name="Description"
                          content="Whitehorse Community Choir, including The Chamber Choir, The Persephone Singers and The Neptunes."/>
                    <link rel="icon" href="/favicon.ico?v=1.1"/>
                </Helmet>
                <div className="boxed">
                    <header id="header" className="d-none d-sm-block">
                        <Container>
                            <div id="header-title">
                                <h1 className="animated fadeInDown">
                                    <a href="https://www.whitehorsechoir.org">
                                        <img src={WccLogo} width="75" height="68" alt=""
                                             style={{marginRight: "10px"}}/> Whitehorse <span> Community Choir </span>
                                    </a>
                                </h1>
                            </div>
                            <div id="social-header" className="hidden-xs">
                                <a href="https://www.facebook.com/pages/Whitehorse-Community-Choir/202447809778668"
                                   className="social-icon soc-facebook animated fadeInDown animation-delay-3"><FaFacebookF/></a>
                                <a href="https://www.instagram.com/whitehorsecommunitychoir/"
                                   className="social-icon soc-facebook animated fadeInDown animation-delay-3"><FaInstagram/></a>
                            </div>
                        </Container>
                    </header>
                    <nav className="navbar navbar-static-top navbar-mind">
                        <Container>
                            <Nav id="menu-wcc">
                                {menus}
                            </Nav>
                        </Container>
                    </nav>
                    {children}
                    <aside id="footer-widgets">
                        <br/>
                        <Container>
                            <Row>
                                <Col md="4">
                                    <section>
                                        <h4 className="section-title text-white">Contact</h4>
                                        <address>
                                            <strong>Whitehorse Community Choir</strong><br/>
                                            108 Elliott Street, Suite 242<br/>
                                            Whitehorse, YT, Y1A 6C4<br/>
                                            Mail: <a href="mailto:whsecommunitychoir@gmail.com"><span className="text-white font-weight-bold">whsecommunitychoir@gmail.com</span></a>
                                        </address>
                                    </section>
                                </Col>
                            </Row>
                        </Container>
                    </aside>
                    <footer id="footer">
                        <p>&copy; 2001-2024 Whitehorse Community Choir. All rights reserved.</p>
                    </footer>
                </div>
            </React.Fragment>
        );
    }
}
